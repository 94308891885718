<template>
    <div class="bg-white card bs-11">
        <Loader v-if="loading"></Loader>
        <div v-else-if="isEmpty" class="row mt-4">
            <div class="col-md-12 text-center fl-y-c">
                <img src="../../../assets/img/empty-cart.svg" style=" max-width: 50%; max-height: 80%;"
                     class="mb-5">
                <h3>You have no items in your shopping cart.</h3>
                <button type="button"
                        class="lego-btn btn-basic btn-color-primary mt-2 btn-lg btn-block"
                        @click="Redirect">Continue Shopping
                </button>
            </div>
        </div>
        <div v-else>
            <div class="fl-te-c mb-3">
                <h2>Your Shopping Cart</h2>
                <btn type="button" size="sm" @click="Redirect" text="Go back" />
            </div>
            <div v-for="(product, index) in cart_items" v-bind:key="index" class="cart-item fl-x-c mb-2 py-2 bb-1">
                <div class="mr-2">
                    <img src="../../../assets/img/No_image_available.svg" :alt="product.name" v-if="!product.img" width="40px"/>
                    <img :src="product.img" alt="Image" class="product-img" height="40" width="40" v-else>
                </div>
                <div class="fl-y-l fl-grow">
                    <b v-html="product.name"></b>
                    <div class="fl-te-c">
                        <div> <cur :value="product.unit_price" /> x {{ product.quantity }} = <b><cur class="text-primary" :value="product.total_price" /></b></div>
                    </div>
                </div>
                <div class="fl-y-c">
                    <c-btn border-radius="0" color="success" icon="fa fa-plus" @click.prevent="addToCart(product, 'plus')"/>
                    <c-btn border-radius="0" color="danger" @click.prevent="addToCart(product, 'minus')" icon="fa fa-minus"/>
                </div>

            </div>
            <div class="fl-te-c">
                <div><b>Total: </b> Rs. <cur :value="totalAmount"></cur></div>
                <btn variant="primary" title="add to cart"
                     class="btn btn-sm" @click="placeOrder()">
                    Place Order
                </btn>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';
import { mapGetters } from 'vuex';
import CardButton from '../../../components/CardButton';
import Loader from '../../../views/loader/loader';
import Cur from '../../../components/Currency';

export default {
    name: 'CartDetails',
    components: {
        Cur,
        'c-btn': CardButton,
        Loader
    },
    data () {
        return {
            data: '',
            loading: false,
            prodLoading: false,
            cart: [],
            cart_items: [],
            model: {
                status: '',
                id: ''
            },
            rules: {
                status: {
                    required: true
                },
                id: {
                    required: true
                }
            }
        };
    },
    beforeMount () {
        this.loadData();
    },

    methods: {
        setData (response) {
            this.loading = true;
            console.log(response.data.data.cart);
            if (response.data) {
                this.cart = response.data.data.cart;
                this.cart_items = response.data.data.cart_items;
                console.log('this.cartItems__org', response.data.data.cart_items);
                this.loading = false;
            }
        },
        async onSubmit () {
            let url;
            if (this.model.id) {
                url = urls.shopOrder.update;
            } else {
                url = urls.shopOrder.create;
            }

            const response = await axios.form(url, this.model);
            if (response.data.error) {
                this.$refs.formValidator.setErrors(response.data.errors);
            } else {
                this.setData(response);
            }
        },
        async loadData () {
            this.loading = true;
            const response = await axios.get(urls.cart.cartdetails);
            this.setData(response);
            this.loading = false;
        },

        Redirect () {
            this.$router.push({ path: '/' });
        },
        placeOrder () {
            this.$router.push({ path: '/address-list/' });
        },
        async addToCart (rowData, action) {
            console.log('rowData =>', rowData);
            if (action === 'first') {
                rowData.quantity = 1;
            } else if (action === 'plus') {
                let qnty = parseInt(rowData.quantity);
                qnty = qnty + 1;
                rowData.quantity = qnty;
            } else {
                rowData.quantity -= 1;
            }
            if (rowData.quantity === 0) {
                this.removeCart(rowData);
            } else {
                console.log('here =>', this.currentUser);
                const postData = {
                    user_id: this.currentUser.id,
                    product_id: rowData.product_id,
                    quantity: rowData.quantity
                };
                console.log('here =>', postData);
                this.prodLoading = true;
                const response = await axios.form(urls.cart.addCard, postData);
                this.prodLoading = false;
                console.log('here =>', response.data);
                if (response.data.error) {
                } else {
                    this.setData(response);
                }
            }
        },
        async removeCart (rowData) {
            this.prodLoading = true;
            const postData = {
                user_id: this.currentUser.id,
                product_id: rowData.product_id
            };
            console.log('postdata' + postData);
            const response = await axios.form(urls.cart.removeCart, postData);
            console.log(response);
            console.log(response.data);
            if (response.data.error === true) {
            } else {
                rowData.count = 0;
                this.setData(response);
                this.prodLoading = false;
            }
        }

    },
    computed: {
        totalAmount () {
            let total = 0;
            const cartItems = this.cart_items;
            const length = cartItems.length;
            for (let i = 0; i < length; i += 1) {
                total += parseInt(cartItems[i].total_price, 10);
            }
            return total;
        },
        isEmpty: function () {
            return this.cart_items.length <= 0;
        },
        ...mapGetters(['currentUser', 'cartItems'])
    }
};
</script>

<style scoped>

</style>
